import React from 'react'
import { Listing } from 'web-components'
import Layout from '../containers/Layout'
import { imageCDNUrl } from '../helpers'

import machines from '../config/text/machines'

class Machines extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/IB7-2-Group-ALTO-espresso-coffee-machine.jpg' heroText={machines.title} title={machines.meta.title} description={machines.meta.description}>
        <div className='machines container'>
          <div className='row'>
            <div className='col-xs-12'>
              <p>{machines.description}</p>
            </div>
          </div>
          <div className='row newMachines'>
            {machines.new.products.map(product => {
              return <Listing
                key={product.name}
                description={product.description}
                footnote={product.footnote}
                img={Array.isArray(product.img) ? 
                    product.img.map(i => {
                      return {
                        ...i,
                        src: imageCDNUrl(i.src)
                      }
                    })
                  : {
                    ...product.img,
                    src: imageCDNUrl(product.img.src)
                  }
                }
                name={product.name}
                {...{ points: product.points }}
              />
            })}
          </div>
          <div className='secondHandMachines'>
            <div className='row'>
              <div className='col-xs-12'>
                <h3>{machines.secondHand.name}</h3>
                <p>{machines.secondHand.description}</p>
              </div>
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-xs-12'>
              <h4>{machines.grinders.name}</h4>
            </div>
          </div>
          <div className='row grinders'>
            {machines.grinders.products.map(product => {
              return <Listing
                key={product.name}
                description={product.description}
                footnote={product.footnote}
                img={product.img}
                name={product.name}
                {...{ points: product.points }}
              />
            })}
          </div>
          "grinders": {
            "name": "Coffee Grinders",
            "products": [
              {
                "name": "Cunill Tranquilo"
              },
              {
                "name": "Cunill Tron Digital On Demand"
              },
              {
                "name": "Fracino Luxomatic Silent On Demand"
              },
              {
                "name": "Macap MXT Timer"
              }
            ]
          } */}
        </div>
      </Layout>
    )
  }
}

export default Machines
